import React, { useState } from "react";
import Modal from "react-modal";


const Legal = () => {

    const [isOpen, setIsOpen] = useState(false);
    const [isOpen2, setIsOpen2] = useState(false);
    const [isOpen3, setIsOpen3] = useState(false);


    function toggleModalOne() {
        setIsOpen(!isOpen);
        window.dataLayer.push({ 'event': 'termsofservice_view' });
    }

    function toggleModalTwo() {
        setIsOpen2(!isOpen2);
        window.dataLayer.push({ 'event': 'privacypolicy_view' });
    }

    function toggleModalThree() {
        setIsOpen3(!isOpen3);
        window.dataLayer.push({ 'event': 'cookiespolicy_view' });
    }
    return (
        <>
            < div className="legal_terms" >
                <a onClick={toggleModalOne}>
                    <span>Terms of Service</span>
                </a>
                <span> | </span>
                <a onClick={toggleModalTwo}>
                    <span>Privacy Policy</span>
                </a>
                <span> | </span>
                <a onClick={toggleModalThree}>
                    <span>Cookies Policy</span>
                </a>
            </div >

            {/* START TERMS OF SERVICE MODAL */}
            < Modal
                isOpen={isOpen}
                onRequestClose={toggleModalOne}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div className="tokyo_tm_modalbox_news">
                    <button className="close-modal" onClick={toggleModalOne}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon" />
                    </button>
                    {/* END CLOSE ICON */}
                    <div className="box_inner">
                        <div className="description_wrap scrollable">
                            <div className="details">
                                <div className="extra">
                                    <p className="date">
                                        <span>Last Updated: August 28, 2023</span>
                                    </p>
                                </div>
                            </div>
                            {/* END DETAILS */}
                            <div className="main_content ">
                                <div className="descriptions">
                                    <h4>Terms of Service</h4>
                                    <p>
                                        Welcome to Asier's Personal Website! Before you proceed, please read and understand the following Terms of Service ("Terms") carefully.
                                        By accessing or using this website, you agree to be bound by these Terms. If you do not agree with any part of these Terms, please refrain from using this website.
                                    </p>

                                    <h6>Acceptance of Terms</h6>
                                    <p>
                                        By accessing or using https://asiergonzalez.es, you agree to comply with and be bound by these Terms. These Terms may be updated from time to time, and your continued use of the website after such changes will constitute your acceptance of the modified Terms.
                                    </p>

                                    <h6>Use of Website Content</h6>
                                    <p>
                                        The content on this website, including but not limited to text, images, graphics, videos, and other materials, is provided for informational purposes only. You may not reproduce, distribute, modify, or otherwise use the content without the explicit permission of Asier González.
                                    </p>

                                    <h6>Restrictions</h6>
                                    <p>
                                        You are specifically restricted from all of the following:
                                        - Publishing any website material in any other media
                                        - Selling, sublicensing, and/or otherwise commercializing any website material
                                        - Using this website in any way that is or may be damaging to this website
                                        - Engaging in any data mining, data harvesting, data extracting, or any other similar activity
                                    </p>

                                    <h6>External Links</h6>
                                    <p>
                                        This website may contain links to external websites or resources, including LinkedIn, Twitter, and YouTube. These links are provided solely for convenience and do not imply endorsement by Asier. I am not responsible for the content, policies, or practices of these external sites. You access and use these links at your own risk.
                                    </p>

                                    <h6>Cookies and Tracking</h6>
                                    <p>
                                        I use Google Analytics to collect information about your use of the website, including your IP address, browser type, pages visited, and time spent on the site. This data helps me improve my website and understand user preferences. By using this website, you consent to the use of cookies and tracking technologies as described in my Privacy Policy.
                                    </p>

                                    <h6>Subscription and Blog</h6>
                                    <p>
                                        If you choose to subscribe to my blog, you agree to provide accurate and current information. You will receive updates and notifications related to the blog through the email address you provide. I reserve the right to modify, suspend, or terminate the blog and subscription service at any time without notice.
                                    </p>

                                    <h6>Intellectual Property</h6>
                                    <p>
                                        All content on this website is protected by intellectual property laws. You may not use, reproduce, or distribute any content without obtaining permission from Asier Gonzalez. Any unauthorized use may violate copyright, trademark, and other applicable laws.
                                    </p>

                                    <h6>Disclaimer</h6>
                                    <p>The content on this website is provided "as is" without warranties of any kind, either express or implied. Asier Gonzalez  disclaims all liability for any errors, inaccuracies, or omissions in the content. You use the website at your own risk.
                                    </p>

                                    <h6>Limitation of Liability</h6>
                                    <p>To the extent permitted by law, Asier González shall not be liable for any direct, indirect, incidental, consequential, or punitive damages arising out of your use or inability to use the website.
                                    </p>

                                    <h6> Governing Law</h6>
                                    <p>These Terms shall be governed by and construed in accordance with the laws of Spain, without regard to its conflict of law principles.
                                    </p>

                                    <h6>Contact me</h6>
                                    <p>
                                        If you have any questions about these Terms, please contact me at my Linkedin profile.
                                    </p>
                                    <p>
                                        By accessing or using this website, you acknowledge that you have read, understood, and agreed to these Terms of Service. Thank you for visiting Asier's Personal Website!
                                    </p>
                                    <p>
                                        Asier Gonzalez Gomez |
                                        Paseo de la Castellana 129 | asiergonzalez.es
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END BOX INNER */}
                </div>
            </Modal >
            {/* START PRIVACY POLICY MODAL */}
            < Modal
                isOpen={isOpen2}
                onRequestClose={toggleModalTwo}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div className="tokyo_tm_modalbox_news">
                    <button className="close-modal" onClick={toggleModalTwo}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon" />
                    </button>
                    {/* END CLOSE ICON */}
                    <div className="box_inner">
                        <div className="description_wrap scrollable">
                            <div className="details">
                                <div className="extra">
                                    <p className="date">
                                        <span>Last Updated: August 28, 2023</span>
                                    </p>
                                </div>
                            </div>
                            {/* END DETAILS */}
                            <div className="main_content ">
                                <div className="descriptions">
                                    <h4>Privacy Policy</h4>
                                    <p>
                                        Welcome to Asier's Personal Website! This Privacy Policy outlines how I collect, use, disclose, and protect your personal information. By accessing or using this website, you agree to the practices described in this Privacy Policy.
                                    </p>
                                    <h6>Information I collect</h6>
                                    <p>
                                        I may collect various types of information when you interact with my website, including:
                                    </p>
                                    <p>
                                        Personal Information: When you subscribe to my blog, I collect your email address. Additionally, if you contact me through the provided contact email, I may collect your name and any information you choose to share.
                                    </p>
                                    <p>
                                        Usage Information: I use Google Analytics to collect information about your interactions with the website, such as your IP address, browser type, pages visited, and time spent on the site. This information helps me analyze and improve the website's performance.
                                    </p>

                                    <h6>How I Use Your Information</h6>
                                    <p>
                                        I may use the collected information for the following purposes:
                                    </p>
                                    <p>
                                        To provide and improve my website's content and functionality.
                                    </p>
                                    <p>
                                        To understand user preferences and optimize user experience.
                                    </p>
                                    <p>
                                        To respond to your inquiries or requests.
                                    </p>

                                    <h6>Restrictions</h6>
                                    <p>
                                        You are specifically restricted from all of the following:
                                        - Publishing any website material in any other media
                                        - Selling, sublicensing, and/or otherwise commercializing any website material
                                        - Using this website in any way that is or may be damaging to this website
                                        - Engaging in any data mining, data harvesting, data extracting, or any other similar activity
                                    </p>

                                    <h6>Disclosure of Information</h6>
                                    <p>
                                        I may disclose your information to third parties if required by law or to protect our legal rights.
                                    </p>
                                    <p>
                                        I do not sell, rent, or share your personal information with third parties for marketing purposes.
                                    </p>

                                    <h6>Cookies and Tracking Technologies</h6>
                                    <p>
                                        I use cookies and similar tracking technologies to collect usage data and improve the website's performance. You can modify your browser settings to control or disable cookies.</p>

                                    <h6>External Links</h6>
                                    <p>
                                        My website may contain links to external sites, including social media platforms. I am not responsible for the privacy practices or content of these external sites. I encourage you to review their respective privacy policies.</p>

                                    <h6>Security</h6>
                                    <p>
                                        I take reasonable measures to protect your personal information from unauthorized access, disclosure, or alteration. However, no data transmission over the internet is completely secure. You provide information at your own risk.
                                    </p>

                                    <h6>Children's Privacy</h6>
                                    <p>
                                        My website is not directed at individuals under the age of 13. I do not knowingly collect personal information from children. If you are a parent or guardian and believe your child has provided me with personal information, please contact me.
                                    </p>


                                    <h6>Changes to this Privacy Policy</h6>
                                    <p>
                                        I may update this Privacy Policy from time to time. The "Last Updated" date at the beginning of the policy will indicate the latest revision. Your continued use of the website after any changes signifies your acceptance of the updated Privacy Policy.
                                    </p>

                                    <h6>Contact me</h6>
                                    <p>
                                        If you have any questions about these Privacy Policy, please contact me at my Linkedin profile.
                                    </p>
                                    <p>
                                        By accessing or using this website, you acknowledge that you have read, understood, and agreed to this Privacy Policy. Thank you for visiting Asier's Personal Website!
                                    </p>
                                    <p>
                                        Asier Gonzalez Gomez |
                                        Paseo de la Castellana 129 | asiergonzalez.es
                                    </p>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* END BOX INNER */}
                </div>
            </Modal >
            {/* START COOKIES POLICY MODAL */}
            < Modal
                isOpen={isOpen3}
                onRequestClose={toggleModalThree}
                contentLabel="My dialog"
                className="mymodal"
                overlayClassName="myoverlay"
                closeTimeoutMS={500}
            >
                <div className="tokyo_tm_modalbox_news">
                    <button className="close-modal" onClick={toggleModalThree}>
                        <img src="assets/img/svg/cancel.svg" alt="close icon" />
                    </button>
                    {/* END CLOSE ICON */}
                    <div className="box_inner">
                        <div className="description_wrap scrollable">
                            <div className="details">
                                <div className="extra">
                                    <p className="date">
                                        <span>Last Updated: August 28, 2023</span>
                                    </p>
                                </div>
                            </div>
                            {/* END DETAILS */}
                            <div className="main_content ">
                                <div className="descriptions">
                                    <h4>Cookies Policy</h4>
                                    <p>
                                        Welcome to Asier's Personal Website! This Cookies Policy explains how I use cookies and similar tracking technologies on my website. By accessing or using this website, you consent to the use of cookies as described in this policy.
                                    </p>

                                    <h6>What are Cookies?</h6>
                                    <p>
                                        Cookies are small text files that are stored on your device when you visit a website. They contain information that helps the website recognize your device and remember your preferences for future visits.
                                    </p>
                                    <h6>How I Use Cookies</h6>
                                    <p>
                                        I use cookies and similar technologies for the following purposes:
                                        <ul>
                                            <li>Essential Cookies: These cookies are necessary for the website to function properly. They enable basic functionalities like page navigation and access to secure areas of the website.</li>
                                            <li>Analytical and Performance Cookies: We use tools like Google Analytics to collect information about how visitors use our website. This information helps us understand user behavior and improve the website's performance.</li>
                                            <li>Functionality Cookies: These cookies allow the website to remember your preferences, such as language settings and user interactions, to enhance your experience.
                                            </li>
                                        </ul>
                                    </p>

                                    <h6>Types of Cookies I Use</h6>
                                    <p>
                                        <ul>
                                            <li>Session Cookies: These cookies are temporary and are deleted when you close your browser.
                                            </li>
                                            <li>   Persistent Cookies: These cookies remain on your device for a set period, even after you close your browser.
                                            </li>
                                        </ul>
                                    </p>

                                    <h6>Third-Party Cookies</h6>
                                    <p>
                                        I may also use third-party cookies from service providers such as Google Analytics to gather data about website traffic and usage. These third-party cookies are subject to the respective privacy policies of these providers..
                                    </p>

                                    <h6>Cookies Management</h6>
                                    <p>
                                        You can control and manage cookies through your browser settings. You can choose to block or delete cookies, or set your browser to notify you before a cookie is stored. However, please note that disabling cookies may affect your experience on our website.                          </p>

                                    <h6>Subscription and Blog</h6>
                                    <p>
                                        If you choose to subscribe to my blog, you agree to provide accurate and current information. You will receive updates and notifications related to the blog through the email address you provide. I reserve the right to modify, suspend, or terminate the blog and subscription service at any time without notice.
                                    </p>

                                    <h6>Changes to this Cookies Policy</h6>
                                    <p>
                                        I may update this Cookies Policy from time to time. The "Last Updated" date at the beginning of the policy will indicate the latest revision. Your continued use of the website after any changes signifies your acceptance of the updated Cookies Policy.
                                    </p>clear
                                    <h6>Contact me</h6>
                                    <p>
                                        If you have any questions about these Policy, please contact me at my Linkedin profile.
                                    </p>
                                    <p>
                                        By accessing or using this website, you acknowledge that you have read, understood, and agreed to these Cookies Policy. Thank you for visiting Asier's Personal Website!
                                    </p>
                                    <p>
                                        Asier Gonzalez Gomez |
                                        Paseo de la Castellana 129 | asiergonzalez.es
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* END BOX INNER */}
                </div>
            </Modal >


        </>



    );
};

export default Legal;