const conferences = [
  {
    id: 1,
    title: "From Data to Security",
    subtitle: "Aguas de Burgos · 2018",
    image: "/assets/img/conferences/aguasdeburgosconference.png",
    event: "aguasburgos_conference_view",
    tags: "#aguasdeburgos #iot #smartwater",
    date: "2018",
    path:"/portfolio/aguasdeburgosconference",
    url:"https://asiergonzalez.es/portfolio/aguasdeburgosconference",
    videopath:"https://www.youtube.com/embed/bI3nRrXtrjU"
  },
  {
    id: 2,
    title: "Libelium Webinar · Smart Water",
    subtitle: "Libelium · 2017",
    image: "/assets/img/conferences/libeliumconference.png",
    event: "libelium_webinar_conference_view",
    tags: "#libelium #iot #smartwater",
    date: "2017",
    path:"/portfolio/libeliumconference",
    url:"https://asiergonzalez.es/portfolio/libeliumconference",
    videopath:"https://www.youtube.com/embed/rD4clGE-Mac"
  },
  {
    id: 3,
    title: "Smart Water · MMD",
    subtitle: "Madrid Monitoring Day · 2017",
    image: "/assets/img/conferences/mmd17conference.png",
    event: "mmd17_conference_view",
    tags: "#madridmonitoringday #iot #smartwater",
    date: "2017",
    path:"/portfolio/mmd17conference",
    url:"https://asiergonzalez.es/portfolio/mmd17conference",
    videopath:"https://www.youtube.com/embed/PgnaWsoKl7s"
  },
  {
    id: 4,
    title: "Smart Cities · Introduction",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scintroductionconference.png",
    event: "sc_introduction_conference_view",
    tags: "#smartcities #iot #introduction",
    date: "2017",
    path:"/portfolio/smartcitiesintroductionconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesintroductionconference",
    videopath:"https://www.youtube.com/embed/PRdFx55Aqsw"
  },
  {
    id: 5,
    title: "Smart Cities · Basic Concepts",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scbasicconference.png",
    event: "sc_basics_conference_view",
    tags: "#smartcities #iot #basicconcepts",
    date: "2017",
    path:"/portfolio/smartcitiesbasicconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesbasicconference",
    videopath:"https://www.youtube.com/embed/2Ole85WJvJA"
  },
  {
    id: 6,
    title: "Smart Cities · Technologies",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/sctechconference.png",
    event: "sc_technologies_conference_view",
    tags: "#smartcities #iot #technologies",
    date: "2017",
    path:"/portfolio/smartcitiestechnologiesconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiestechnologiesconference",
    videopath:"https://www.youtube.com/embed/8rYBhKc3SVo"
  },
  {
    id: 7,
    title: "Smart Cities · Services",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scservicesconference.png",
    event: "sc_introduction_services_view",
    tags: "#smartcities #iot #services",
    date: "2017",
    path:"/portfolio/smartcitiesservicesconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesservicesconference",
    videopath:"https://www.youtube.com/embed/OcS-lobkrOo"
  },
  {
    id: 8,
    title: "Smart Cities · Infrastrucutures",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scinfrastructuresconference.png",
    event: "sc_infrastructures_conference_view",
    tags: "#smartcities #iot #infrastructures",
    date: "2017",
    path:"/portfolio/smartcitiesinfrastructuresconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesinfrastructuresconference",
    videopath:"https://www.youtube.com/embed/JHBfXo1dxwI"
  },
  {
    id: 9,
    title: "Smart Cities · Protocols",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scprotocolsconference.png",
    event: "sc_protocols_conference_view",
    tags: "#smartcities #iot #protocols",
    date: "2017",
    path:"/portfolio/smartcitiesprotocolsconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesprotocolsconference",
    videopath:"https://www.youtube.com/embed/j1B0k05iMWA"
  },
  {
    id: 10,
    title: "Smart Cities · Platforms",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scplatformconference.png",
    event: "sc_platform_conference_view",
    tags: "#smartcities #iot #platform",
    date: "2017",
    path:"/portfolio/smartcitiesplatformconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesplatformconference",
    videopath:"https://www.youtube.com/embed/BkJERMWVRCw"
  },
  {
    id: 11,
    title: "Smart Cities · Standards",
    subtitle: "Structuralia · Incubicon · 2017",
    image: "/assets/img/conferences/scstandardsconference.png",
    event: "sc_introduction_standards_view",
    tags: "#smartcities #iot #standards",
    date: "2017",
    path:"/portfolio/smartcitiesstandardsconference",
    url:"https://asiergonzalez.es/portfolio/smartcitiesstandardsconference",
    videopath:"https://www.youtube.com/embed/ZEoK5OwQiW4"
  }
];

export default conferences;
