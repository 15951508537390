import React, { useState } from "react";
import Modal from "react-modal";
import Social from "../Social";

Modal.setAppElement("#root");

const Services = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(e) {
    setIsOpen(!isOpen);
    e.preventDefault();
  }

  const serviceContent = [
    {
      id: 1,
      no: "01",
      title: "IoT Strategy Development",
      text: `Assist businesses in formulating and implementing effective IoT strategies that align with their goals. Identify IoT opportunities, assess feasibility, define roadmaps, and recommend technologies and platforms.`,
    },
    {
      id: 2,
      no: "02",
      title: "Digital Transformation",
      text: `Help companies digitally transform their operations and processes. Evaluate existing systems, recommend solutions for process digitalization, and guide the integration of technologies like IoT, AI, and Cloud platforms.`,
    },
    {
      id: 3,
      no: "03",
      title: "Product and Service Ideation",
      text: `Collaborate with businesses to brainstorm and conceptualize new products and services leveraging emerging technologies. Provide insights into innovative use cases and business models.`,
    },
    {
      id: 4,
      no: "04",
      title: "Technology Adoption and Integration",
      text: `Advise on the integration and adoption of technologies like IoT, AI, Big Data, and Cybersecurity. Provide guidance on selecting the right tools, platforms, and frameworks for specific business needs.`,
    },
    {
      id: 5,
      no: "05",
      title: "Market Research and Analysis",
      text: `Conduct market research to identify trends, opportunities, and potential competitors in specific industries. Provide insights that can guide businesses in making informed decisions.`,
    },
    {
      id: 6,
      no: "06",
      title: "Solution Architecture Design",
      text: `Create detailed technical architectures and specifications for IoT solutions. Define how different components will work together, ensuring scalability, security, and reliability.`,
    },
    {
      id: 7,
      no: "07",
      title: "Product Management & Roadmapping",
      text: `Oversee the execution of Products from inception to completion. Manage teams, roadmaps, timelines, budgets, and deliverables to ensure successful project outcomes.`,
    },
    {
      id: 8,
      no: "08",
      title: "Partnership Development",
      text: ` Identify strategic partnerships and collaborations to enhance the adoption of IoT and related technologies. Help clients establish partnerships that can accelerate growth and innovation.`,
    },
    {
      id: 9,
      no: "09",
      title: "POCs and MVPs Development",
      text: `Develop and oversee POCs/MVPs to demonstrate the feasibility and potential benefits of IoT solutions. Guide clients through the innovation funnel process, from idea to scale making required POCs and MVPs to validate the solution and the market.`,
    },
    {
      id: 10,
      no: "10",
      title: "Technical Writing and Documentation",
      text: `Create comprehensive documentation, whitepapers, and technical guides related to IoT solutions, technologies, and implementations.`,
    },
    {
      id: 11,
      no: "11",
      title: "Strategic Planning and Business Development",
      text: `Assist companies in aligning their business strategies with IoT and emerging technologies. Provide insights into how IoT can drive growth, create new revenue streams, and enhance customer experiences.`,
    },
    {
      id: 12,
      no: "12",
      title: "Startup Mentorship",
      text: `Share startup experience by mentoring early-stage entrepreneurs on building and scaling innovative ventures.`,
    },
    {
      id: 13,
      no: "13",
      title: "Project Evaluation",
      text: `Evaluate external ICT projects for fundings as an independant evaluator.`,
    },
    {
      id: 13,
      no: "14",
      title: "Speaker",
      text: `Conduct speechs in congress and trade events relate to emerging technologies.`,
    },
    {
      id: 15,
      no: "15",
      title: "Training and Workshops",
      text: `Conduct training sessions and workshops to educate clients and their teams about IoT concepts, technologies, and best practices. Equip them with the knowledge to drive internal innovation.`,
    },
  ];

  return (
    <>
      {serviceContent.map((item) => (
        <li key={item.id}>
          <div className="list_inner">
            <span className="number">{item.no}</span>
            <h3 className="title">{item.title}</h3>
            <p className="text">{item.text}</p>
            {/* <div className="tokyo_tm_read_more">
              <a href="#" onClick={toggleModal}>
                <span>Read More</span>
              </a>
            </div> */}
          </div>
        </li>
      ))}

      {/* START MODAL */}
      <Modal
        isOpen={isOpen}
        onRequestClose={toggleModal}
        contentLabel="My dialog"
        className="mymodal"
        overlayClassName="myoverlay"
        closeTimeoutMS={500}
      >
        <div className="tokyo_tm_modalbox_news">
          <button className="close-modal" onClick={toggleModal}>
            <img src="assets/img/svg/cancel.svg" alt="close icon" />
          </button>
          {/* END CLOSE ICON */}
          <div className="box_inner">
            <div className="description_wrap scrollable">
              <div className="image">
                <img src="assets/img/thumbs/4-3.jpg" alt="tumb" />
                <div
                  className="main"
                  style={{
                    backgroundImage: "url(assets/img/news/2.jpg)",
                  }}
                ></div>
              </div>
              {/* END IMAGE */}
              <div className="details">
                <div className="extra">
                  <p className="date">
                    By <a href="#">Alex Watson</a>
                    <span>05 April 2021</span>
                  </p>
                </div>
                <h3 className="title">
                  Format releases a new tool that enables direct video hosting
                </h3>
              </div>
              {/* END DETAILS */}
              <div className="main_content ">
                <div className="descriptions">
                  <p className="bigger">
                    Just because we can't get out and about like we normally
                    would, doesn’t mean we have to stop taking pictures. There’s
                    still plenty you can do, provided you're prepared to use
                    some imagination. Here are a few ideas to keep you shooting
                    until normal life resumes.
                  </p>
                  <p>
                    Most photographers love to shoot the unusual, and you don’t
                    get much more unusual than These Unprecedented Times. Right
                    now everything counts as out of the ordinary. There are a
                    number of remarkable things about these lockdown days that
                    are worth photographing now so we can remember them when it
                    is all over.
                  </p>
                  <p>
                    Streets empty that are usually busy are remarkable and can
                    evoke the sense of historical pictures from before the
                    invention of the motorcar. Other things that are different
                    at the moment will be queues to get into stores and the
                    lines marked out on the floor to show how far apart we
                    should be.
                  </p>
                  <div className="quotebox">
                    <div className="icon">
                      <img
                        className="svg"
                        src="assets/img/svg/quote.svg"
                        alt="tumb"
                      />
                    </div>
                    <p>
                      Most photographers find it hard to see interesting
                      pictures in places in which they are most familiar. A trip
                      somewhere new seems always exactly what our photography
                      needed, as shooting away from home consistently inspires
                      us to new artistic heights.
                    </p>
                  </div>
                  {/* END QUOTEBOX */}
                  <p>
                    Pretend everything is new and that you haven’t seen it
                    before, and then you will be free to notice the leading
                    lines, the places where one edge meets another in delightful
                    geometric harmony, and how the ordinary things in the
                    kitchen are transformed when the light is on or off.
                  </p>
                  <p>
                    The trick here is to look slowly, and then look again. Take
                    the time to look in detail and to look at the same thing
                    from different angles, with different light, long lenses and
                    wide lenses. Then move to the left a bit. You may never feel
                    the need to leave the house again.
                  </p>
                </div>
                {/* END DESCRIPTION */}
                <div className="news_share">
                  <span>Share:</span>
                  <Social />
                  {/* END SOCIAL SHARE */}
                </div>
                {/* END NEWS SHARE */}
              </div>
            </div>
          </div>
          {/* END BOX INNER */}
        </div>
        {/* END MODALBOX NEWS */}
      </Modal>
      {/* END MODAL */}
    </>
  );
};

export default Services;
