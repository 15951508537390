import React, { useState } from 'react';
import "./pdf.css";
import { Document, Page, pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;



const PdfViewer = (props) => {

  const [PDFFile] = useState(props.PDFfile);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber(prevPageNumber => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  return (
    <>
      <div className="pdflayer">
        <Document
          file={PDFFile}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          <Page pageNumber={pageNumber} />
        </Document>
      </div>
      
      <div className='page_controls'>
        <button
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
          style={{backgroundColor:"white",border: "none",color:"#00ca77",padding:"15px 32px"}}        >
          ‹
        </button>
        <span>
        {pageNumber || (numPages ? 1 : '--')} of {numPages || '--'}
        </span>
        <button
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
          style={{backgroundColor:"white",border: "none",color:"#00ca77",padding:"15px 32px"}}
        >
          ›
        </button>
      </div>
    </>
  );
};

export default PdfViewer;