import React, { useState } from 'react';

const ShareableLink = ({ url }) => {
  const [copySuccess, setCopySuccess] = useState('');
  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopySuccess('Copied!');
    } catch (err) {
      setCopySuccess('Try again.');
    }
  };
  return (
    <div>
      <a onClick={handleCopyClick}>Copy Link</a>
      {/* {copySuccess && <p>{copySuccess}</p>} */}
    </div>
  );
};
export default ShareableLink;





