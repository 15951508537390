import React from "react";
import { useFormFields, useMailChimpForm } from "./use-mailchimp-form.tsx";

export default function App() {
  const url = "https://asiergonzalez.us9.list-manage.com/subscribe/post?u=16aabb7f525284c0ceb6f0060&amp;id=a161b75c14";
    // The url looks like the url below:
  // https://aaaaaaaaa.us20.list-manage.com/subscribe/post?u=xxxxxxxxxxxxxxxxxx&amp;id=yyyyyyyyyy
  const { loading, error, success, message, handleSubmit } = useMailChimpForm(
    url
  );
  const { fields, handleFieldChange } = useFormFields({
    EMAIL: ""
  });
  return (
    <>
      <form
        onSubmit={event => {
          event.preventDefault();
          handleSubmit(fields);
        }}
      >
        <input style={{outline:"none"}}
          id="EMAIL"
          width="10px"
          //autoFocus
          type="email"
          value={fields.EMAIL}
          onChange={handleFieldChange}
        />
        <button style={{backgroundColor:"#00ca77",border: "none",color:"white",padding:"15px 32px"}}>SUBSCRIBE</button>
      </form>
      {loading && "submitting"}
      {error && message}
      {success && message}
    </>
  );
}
