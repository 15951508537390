import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Testimonial = () => {
  var settings = {
    dots: false,
    arrow: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const testimonialContent = [
    {
      id: 1,
      text: `"Asier has been my colleague in various telecommunications projects within the water sector. He has consistently shown great leadership skills, understanding both customer needs and those of the working team. He is an excellent project manager, capable of finding imaginative and innovative solutions, always promoting a positive and cheerful spirit."`,
      avatar: "url(https://media.licdn.com/dms/image/C5603AQFJWhs07hQl_A/profile-displayphoto-shrink_200_200/0/1569230263602?e=1681948800&v=beta&t=zstnskaYq4RCJtEzISpsrzS9g26ouzDHKDsRfX-idq0)",
      name: "Irene Carlos Lorenzo",
      designation: "Senior Technician - International Projects in FECYT",
      link: "https://www.linkedin.com/in/irene-carlos-lorenzo-25483145/",
    },
    {
      id: 2,
      text: `"Asier is a great project and people manager. He is highly professional with extensive technical knowledge, in addition to being a wonderful person. He has no hesitation in taking responsibility or lending a hand whenever needed, and he knows how to listen to others with respect, using it constructively. He is adept at capturing clients' attention and extracting the relevant issues from them, in order to provide the best solution in each case. It has been a pleasure working with him."`,
      avatar: "url(https://media.licdn.com/dms/image/C4E03AQEpmjowysEPYQ/profile-displayphoto-shrink_200_200/0/1656577391499?e=1681948800&v=beta&t=WqbeTAC7SqEMliKY74w7IWkg9bSiuAKeYLNbdbNeiAQ)",
      name: "Álvaro Álvarez Sandonis",
      designation: "Data Specialist at Michelin",
      link:"https://www.linkedin.com/in/aasandonis/",
    },
    {
      id: 3,
      text: `"Asier possesses a work ethic and perseverance worthy of admiration. His extensive technical knowledge, combined with a perfectionist nature, ensures that no aspect of the project is overlooked. Throughout the development of the Smart Horse platform, it was an absolute pleasure to work with him, both in professional interactions and on a personal level."`,
      avatar: "url(https://media.licdn.com/dms/image/C5603AQFJaMlCuZmNIQ/profile-displayphoto-shrink_200_200/0/1570787146108?e=1681948800&v=beta&t=OsFVK5KV4tTPdIOHzcmadPUTFV3l26J_fc4m_Bh3rAM)",
      name: "Daniel Sanz",
      designation: "Business Development and Ecosystem at Libelium",
      link:"https://www.linkedin.com/in/daniel-sanz-falcon/",
    },
    {
      id: 4,
      text: `"Asier has been promoting the establishment of a new technological company, showcasing significant work capacity, technical expertise, versatility, and risk management. He has led the project, overseeing the creation of the business plan, technological developments, prototype manufacturing, and the commercial presentation of the proposal. I believe he is a highly capable, independent, and responsible individual"`,
      avatar: "url(https://media.licdn.com/dms/image/C4D03AQEXfZzrTBcARw/profile-displayphoto-shrink_200_200/0/1569311635056?e=1681948800&v=beta&t=vAr8NUj6e1YxwfyEwA0j1IUui6XJ-WD-KY-s6Tca-Xk)",
      name: "Juan Bernardo Arrue Mendizabal",
      designation: " Business Incubator Manager in BEAZ",
      link: "https://www.linkedin.com/in/juan-bernardo-arrue-mendizabal-744b9a71/",
    },
    {
      id: 5,
      text: `"I was responsible for Asier in the R&D Department at Arson, and throughout his time there, he consistently demonstrated strong technical capability, high efficiency, self-sufficiency, and a sound working methodology. As the Quality Manager at Arson, he exhibited an excellent project management ability and commitment to implementing the ISO9001 Standard within the company. On a personal level, Asier seamlessly integrated into the work group, showcasing significant teamwork skills."`,
      avatar: "url(https://media.licdn.com/dms/image/C4E03AQHDpsn1wDeIWA/profile-displayphoto-shrink_200_200/0/1517397387361?e=1681948800&v=beta&t=2_AxWr0RyRIYt0H5QF3JZcsepQkpWimLuE42t_TdDPU)",
      name: "Sergio Hernando Núñez",
      designation: "I+D Manager in IoT Water Analitycs",
      link: "https://www.linkedin.com/in/sergio-hernando-núñez-5a327290/",
    },
    {
      id: 6,
      text: `"I worked for Asier as a freelancer on the SmartHorse project. It has been a pleasure to work with him. He is an organized individual, technically proficient, and deeply engaged in his work. I would gladly work with him again without hesitation"`,
      avatar: "url(https://media.licdn.com/dms/image/C5103AQG9AsoyCis7OA/profile-displayphoto-shrink_200_200/0/1516486118011?e=1681948800&v=beta&t=JcmUpeNWkwYCdYHVECQDt_ErrS_WngzyFbkfOKVC4lk)",
      name: "Marcos López Miguel",
      designation: "Java EE Developer in TuLotero",
      link: "https://www.linkedin.com/in/marcos-lópez-miguel-b1406151/",
    },
    {
      id: 7,
      text: `"I met Asier when he was the Quality Manager at Engineering Arson, and I can affirm that his discipline, work methodology, and efficiency are excellent. Moreover, he is an individual who takes on responsibilities with great dedication and commitment, all while maintaining a collaborative team spirit. He cooperated with me and others, always bringing his usual friendliness and positive atmosphere."`,
      avatar: "url(https://media.licdn.com/dms/image/C4D03AQFMOXde-q2xHg/profile-displayphoto-shrink_200_200/0/1568299661599?e=1681948800&v=beta&t=fkYZhR1tD4s0T-34uDFyrB7msEPCiZPcS9nRhnc3ByY)",
      name: "Montse Segarra Campaña",
      designation: "Supervisora in PKF-Attest",
      link: "https://www.linkedin.com/in/montse-segarra-campaña-40585517/",
    },
  ];

  return (
    <ul className="testimonila-slider-wrapper">
      <Slider {...settings} arrows={false}>
        {testimonialContent.map((item) => (
          <li key={item.id}>
            <div className="list_inner">
              <div className="text">
                <p>{item.text}</p>
              </div>
              <div className="details">
                <div className="image">
                  <div
                    className="main"
                    style={{
                      backgroundImage: item.avatar,
                    }}
                  />
                </div>
                <div className="info">
                  <h3 style={{color:"#333b4d"}}>{item.name}</h3>
                  <span>{item.designation}</span><br></br>
                  <a href={`${item.link}`} target="_blank" rel="noreferrer">
              <img
                className="svg"
                src={`/assets/img/svg/social/linkedin.svg`}
                alt="social"
              ></img>
              </a>
                </div>
              </div>
            </div>
          </li>
        ))}
      </Slider>
    </ul>
  );
};

export default Testimonial;
