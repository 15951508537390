import React from "react";
import HomeLight from "../views/all-home-version/HomeLight";
import NotFound from "../views/NotFound";
import { Routes, Route } from "react-router-dom";
import Portfolio from "../components/portfolio/Portfolio";
import Home from "../components/Home";
import AboutMain from "../components/about/AboutMain";
import ServiceMain from "../components/service/ServiceMain";
import Blog from "../components/blog/Blog";

const AllRoutes = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<HomeLight />}>
          <Route index element={<Home />} />
          <Route path="/about" element={<AboutMain />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/portfolio/:i" element={<Portfolio />} />
          <Route path="/services" element={<ServiceMain />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:i" element={<Blog />} />
          <Route path="*" element={<NotFound />} />
        </Route>
      </Routes>
    </>
  );
};

export default AllRoutes;
