import React from "react";

const CopyRight = () => {
  return (
    <div className="copyright">
      <p>
        &copy; {new Date().getFullYear()}· Created by
        <a
          href="https://asiergonzalez.es"
          target="_blank"
          rel="noreferrer"
        >
          Asier
        </a>
        <br />
      </p>
    </div>
  );
};

export default CopyRight;
