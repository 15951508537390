const projects = [
  {
    id: 3,
    title: "Global Product & Services for Stored Grain",
    subtitle: "SGS · 2022",
    category: "Smart Agriculture",
    image: "/assets/img/portfolio/smartwarehousefront.png",
    description: "Mitigate risks, ensuring quality and long-term value",
    detailImage: "/assets/img/portfolio/smartwarehouseback.jpeg",
    content: `
    <p>
      Mitigate risks, ensuring the quality and long-term value of your products through the SGS Internet of Things (IoT) Smart Warehouse. Monitor and protect stored goods, assets and commodities with Smart Warehouse from SGS.
      Temperature increase, humidity or water leakage can threaten inventories during storage. Our internet of Things (IoT) Smart Warehouse enables you to mitigate these risks, ensuring the quality and long-term value of your products.
      Smart Warehouse delivers timely and accurate information to protect stored goods, assets and commodities. It features advanced analytic and artificial intelligence (AI) systems to identify issues and enable preventive intervention before any degradation occurs.
      Our proprietary solution, Grain Smart Warehouse, helps you to monitor conditions of stored commodities. It addresses problems such as theft, rain, flood, variation in temperature and humidity, rodents and insects, which occur when grains and oilseeds are stored at warehouses using traditional technology.
    </p>
    <p>
      Smart Warehouse enables you to:
      <ul>
        <li>Gain real time monitoring using our online platform  </li><br />
        <li>Quickly and accurately detect the affected area</li><br />
        <li>Receive alarms and required information for quick action</li><br />
        <li>Secure the value of stored goods in an efficient, innovative and affordable way</li><br />
        <li>Automate and simplify monitoring by receiving periodic measurements of most relevant parameters</li><br />
        <li>Access real time results online and react to potential problems without delay</li><br />
        <li>Anticipate events and react before a problem occurs with our advanced predictive analytical AI systems  </li>
      </ul>
    </p>
      `,
    event: "smartwarehouse_project_view",
    tags: "#grain #iot #quality #lorawan #azure",
    company: "SGS",
    website: "https://campaigns.sgs.com/en/vr/com/campaigns/sgs-smart-warehouse",
    date: "2022",
    brochure: "/assets/brochures/smartwarehouse.pdf",
    path:"/portfolio/smartwarehouse",
    url:"https://asiergonzalez.es/portfolio/smartwarehouse",
    video:"Smartwarehouse",
    videolink1: "https://www.youtube.com/embed/a9gXr23AvX4"
  },
  {
    id: 4,
    title: "IoT Global Platform for Digital Inspections",
    subtitle: "SGS · 2021",
    category: "Technology Enabler",
    image: "/assets/img/portfolio/iotplatformfront.png",
    description: "Connect, Enhance, Scale, Decide, Visualize, Secure",
    detailImage: "/assets/img/portfolio/iotplatformback.jpeg",
    content: `
    <p>
      IoT Global Platform for Digital Services:
    </p>
    <ul>
      <li>Easy To Connect: Hardware and Data-sources to the Cloud. Connect your hardware and/or digital data-services to SGS’ device agnostic cloud using open standards-based communications like MQTT and HTTP.</li><br />
      <li>Add Value To your IoT Data: Identify, aggregate and transform data from your IoT sources and use it to understand current conditions and trends, extract and combine data for insights, KPIs, analytics data.</li><br />
      <li>Scalability and Extendibility: Provides ability to upgrade to scale resources and  microservices for high availability, vertical scalability and horizontal extendibility. Pay by your data consumption.</li><br />
      <li>Make Better Business Decision: Solve business issues thanks to usage and performance patterns, anomaly detection, data and transaction validations, predictive real-time and edge analysis.</li><br />
      <li>Consume Data and  Dashboards: Manage risk and gather insights across your entire IoT landscape, using your /our dashboards and sophisticated alerts to monitor devices, apps, and connections</li><br />
      <li>Security: Supports transport encryption for AMQP, MQTT and HTTP(s) protocols. Supports device/apps authentication and device /apps credentials management.</li>
    </ul>
      `,
    event: "sgs_iotplatform_project_view",
    tags: "#iot #platform #azure #iothub #digitaltwin",
    company: "SGS",
    website: "https://iot.sgs.com",
    date: "2021",
    path:"/portfolio/iothubplatform",
    url:"https://asiergonzalez.es/portfolio/iothubplatform",
    video:"Teaser",
    videolink1:"https://www.youtube.com/embed/8yjA3D0uRN0"
  },
  {
    id: 5,
    title: "Water Consumption Monitoring in Prisons",
    subtitle: "TPF · ACING · 2018",
    category: "Smart Water",
    image: "/assets/img/portfolio/iot4waterprisonsfront.png",
    description: " Monitor, Optimize, and Stay Informed",
    detailImage: "/assets/img/portfolio/iot4waterprisonsback.jpeg",
    content: `
    <p>TPF INGENIERIA is providing several Penitentiary Centers with the monitoring service of the water supply, including the monitoring of maximum and minimum flows with the final objective of optimizing water consumption in said centers.</p>
    <p>Customers can access to the graphs of the evolution of hourly, daily and monthly consumption of your Penitentiary Center, as well as graphs with the comparison between water consumption in recent years. Likewise, you can consult and download the reports published monthly with the evolution of consumption and the collection of anomalies that have occurred during the previous month.</p>         
      `,
    event: "ccpp_project_view",
    tags: "#iot #smartwater #prisons #lorawan #sigfox #web",
    company: "TPF INGENIERIA",
    website: "http://ccpp.iot4water.com",
    date: "2018",
    path:"/portfolio/smartwaterprisons",
    url:"https://asiergonzalez.es/portfolio/smartwaterprisons",
  },
  {
    id: 6,
    title: "Improving Water Efficiency and Security",
    subtitle: "TPF · ACING · 2016",
    category: "Smart Water",
    image: "/assets/img/portfolio/iot4wateriweslafront.png",
    description: "Revolutionizing Water Management for Smart Living",
    detailImage: "/assets/img/portfolio/iot4wateriweslaback.jpeg",
    content: `
    <p>IWESLA · Improving Water Efficiency and Security in Living Areas</p>
    <p>The current rate of growth of the population in cities together with climate change effects require the optimization of natural resources usage. The intelligent use of water in smart cities, or “smart water”, implies engagement and involvement from the water utilities and from the demand side or end-users. The proposed experiment aims to develop and implement a demand-side cyber-physical system (CPS) to optimize the water consumption efficiency and safety in living areas.</p>
    <p><ul>
    <li>WATER HARDWARE INFORMATION SOURCES & SINKS:In order to carry out the monitoring and control actions of the CPS, each one of the facilities employed for the experiment will be dotted with water meters and presence sensors, as information sources; as well as electrovalve actuators to obtain control over the water network.</li><br />
    <li>SOFIA2 MIDDLEWARE PLATFORM BASED:SOFIA2 is a middleware that allows the interoperability of multiple systems and devices, offering a semantic platform to make real world information available to smart applications (Internet of Things).</li><br />
    <li>BIG DATA & STREAMING ANALYTICS SERVICES:An autonomous multivariate monitoring of the streaming data will allow the system detect patterns and anomalies and spot predictive alerts for user an operators. Additionally it will provide real-time user profiling which will allow segmenting usage patterns and identifying the different water usage based on users, days, etc</li><br />
    <li>LIVING AREA WATER EFFICIENCY AND SAFETY APPLICATION:The living area water efficiency and safety app will alert the facilities management staff about abnormal water usage detected and will provide the option of acting over the supply network using the actuators. The application will also provide the option to allow automatic actuation on the network based on certain rules defined by the user.</li><br />
    <li>CITIZEN AWARENESS APPLICATION: It will provide up-to-date information and indicators on the disaggregated water usage in the facilities monitored in order to create awareness on efficiency and potential improvements.</li><br />
    </ul>
    </p>
      `,
    event: "iwesla_project_view",
    tags: "#iot #smartwater #building #cellular #ai/ml #cpslabs",
    company: "TPF INGENIERIA",
    website: "http://iwesla.iot4water.com",
    date: "2016",
    path:"/portfolio/iwesla",
    url:"https://asiergonzalez.es/portfolio/iwesla",
    press1: "El Economista",
    press2: "Libelium World",
    press3: "eSmartCity.es",
    press4:"eSmartCity.es",
    presslink1:"https://www.eleconomista.es/empresas-finanzas/noticias/8249706/03/17/Indra-ofrece-a-las-ciudades-ahorros-del-40-en-el-consumo-de-agua-.html",
    presslink2:"https://www.libelium.com/libeliumworld/success-stories/saving-water-with-smart-management-and-efficient-systems-in-spain/",
    presslink3:"https://www.esmartcity.es/comunicaciones/comunicacion-sistema-inteligente-gestion-del-agua-desarrollado-escuela-primaria-rivas-vaciamadrid-programa-estrategico-luz-verde-la-ciudad",
    presslink4:"https://www.esmartcity.es/comunicaciones/comunicacion-mejora-eficiencia-seguridad-consumo-de-agua-edificios-aplicando-sistemas-ciber-fisicos",
    image1: "/assets/img/portfolio/iot4wateriwesla1.jpeg",
    image2: "/assets/img/portfolio/iot4wateriwesla4.jpeg",
    image3: "/assets/img/portfolio/iot4wateriwesla3.jpeg",
    image4: "/assets/img/portfolio/iot4wateriwesla2.jpeg"
  },
  {
    id: 7,
    title: "AEMET, SAIH and SAICA Monitoring System",
    subtitle: "TPF · ACING · 2016",
    category: "Smart Infrastructure",
    image: "/assets/img/portfolio/chgfront.png",
    description: "Elevating Water Monitoring for Operational Efficiency",
    detailImage: "/assets/img/portfolio/chgback.jpeg",
    content: `
    <p>The project developed for the Guadalquivir hydrological confederation through IDBOX integrates hydrological information from the AEMET, SAIH and SAICA station networks.<p>
    <p>The signals generated in the SAICA network include physicochemical parameters that characterize the quality of the water in 15 installed stations</p>
    <p>IDboxRT is a platform for monitoring industrial processes. It integrates all available information sources, processes that capture signals and offers analysis tools to assist operational decision making. All the collected data is processed according to the defined business rules generating new values and new forms of display, providing increased and continuous business value. The goal is to have centralized information on a single platform available from mobile devices. Each user will have access to specific and personalized information which will help in better decision making and increase the company´s productivity.</p>
    `,
    event: "chg_project_view",
    tags: "#iot #smartinfrastructure #scada #idbox",
    company: "TPF INGENIERIA",
    website: "http://chg.iot4water.com",
    date: "2016",
    path:"/portfolio/chg",
    url:"https://asiergonzalez.es/portfolio/chg",
    image1: "/assets/img/portfolio/chg2.jpeg",
    image2: "/assets/img/portfolio/chg1.jpeg",
    image3: "/assets/img/portfolio/chg3.jpeg",
    press1: "iAgua",
    presslink1:"https://www.iagua.es/noticias/espana/ep/15/04/05/prision-cordoba-pionera-ahorro-agua",
    video:"Teaser",
    videolink1:"https://www.youtube.com/embed/SuH5AhSgw0g"
  },
  {
    id: 8,
    title: "iMetland · Waste Water Monitoring System",
    subtitle: "TPF · ACING · 2018",
    category: "Smart Water",
    image: "/assets/img/portfolio/imetlandfront.png",
    description: "Simply giving water a second life",
    detailImage: "/assets/img/portfolio/imetlandback.jpeg",
    content: `
    <p>iMETland is an eco-friendly device which purifies urban wastewater from small communities at zero-energy operation cost, creating a virtuous circle safeguarding local environment and connecting water, energy, ICT and land resources.</p>
    <p>IDboxRT is a platform for monitoring industrial processes. It integrates all available information sources, processes that capture signals and offers analysis tools to assist operational decision making. All the collected data is processed according to the defined business rules generating new values and new forms of display, providing increased and continuous business value. The goal is to have centralized information on a single platform available from mobile devices. Each user will have access to specific and personalized information which will help in better decision making and increase the company´s productivity.</p>
    `,
    event: "imetland_project_view",
    tags: "#iot #h2020 #lorawan #cellular #idbox",
    company: "TPF INGENIERIA",
    website: "http://imetland.iot4water.com",
    date: "2015",
    path:"/portfolio/imetland",
    url:"https://asiergonzalez.es/portfolio/imetland",
    image1: "/assets/img/portfolio/imetland1.jpeg",
    image2: "/assets/img/portfolio/imetland2.jpeg",
    image3: "/assets/img/portfolio/imetland3.jpeg",
    press1: "Engineering.com",
    press2: "eSmartCity.es",
    press3: "Libelium World",
    press4:"eSmartCity.es",
    presslink1:"https://www.engineering.com/story/wastewater-treatment-with-the-internet-of-things",
    presslink2:"https://www.esmartcity.es/2017/05/04/segunda-vida-aguas-residuales-zonas-rurales-a-traves-tecnologia",
    presslink3:"https://www.libelium.com/libeliumworld/success-stories/smart-water-management-for-wastewater-treatment-in-isolated-communities/",
    presslink4:"https://www.esmartcity.es/comunicaciones/monitorizacion-control-humedales-para-optimizar-proceso-depuracion-aguas-residuales-urbanas-comunidades-inteligentes-sostenibles",
    video:"Teaser",
    videolink1: "https://www.youtube.com/watch?v=Ko0TWHKa14k&feature=youtu.be"
  },
  {
    id: 9,
    title: "Water Consumption and Pipes Monitoring ",
    subtitle: "TPF · ACING · 2017",
    category: "Smart Water",
    image: "/assets/img/portfolio/orangefront.png",
    description: "Water Management for Efficiency and Quality",
    detailImage: "/assets/img/portfolio/orangeback.jpeg",
    content: `
    <p>Information technologies at the service of large water consumers. Orange deployed this IoT project applied to networks and water uses working with the most advanced technologies to monitor and control from supply/sanitation facilities, water treatment systems as well as large water consumers.</p>
    <p>Monitoring of sanitation and supply networks through different sensors to measure volume, water flow and water quality as well as pressures and transients in the network. All this through low consumption wireless sensors and the latest mobile technologies.</p>
    `,
    event: "orange_project_view",
    tags: "#iot #smartwater #carto #sigfox #web #orange",
    company: "TPF INGENIERIA",
    website: "http://orange.iot4water.com",
    date: "2016",
    path:"/portfolio/smartwaterorange",
    url:"https://asiergonzalez.es/portfolio/smartwaterorange",
    image1: "/assets/img/portfolio/orange1.jpeg",
  },
  {
    id: 10,
    title: "Alcobendas Water Consumption Monitoring",
    subtitle: "TPF · ACING · 2017",
    category: "Smart Water",
    image: "/assets/img/portfolio/alcobendasfront.png",
    description: "Water Management for Efficiency and Quality",
    detailImage: "/assets/img/portfolio/alcobendasback.jpeg",
    content: `
    <p>Latest technologies in water consumption monitoring for controlling water consumption in Alcobendas city.</p>
    <p>IDboxRT is a platform for monitoring industrial processes. It integrates all available information sources, processes that capture signals and offers analysis tools to assist operational decision making.All the collected data is processed according to the defined business rules generating new values and new forms of display, providing increased and continuous business value. The goal is to have centralized information on a single platform available from mobile devices. Each user will have access to specific and personalized information which will help in better decision making and increase the company´s productivity.</p>
    `,
    event: "alcobendas_project_view",
    tags: "#iot #smartwater #idbox #sigfox #web #alcobendas",
    company: "TPF INGENIERIA",
    website: "http://alcobendas.iot4water.com",
    date: "2017",
    path:"/portfolio/smartwateralcobendas",
    url:"https://asiergonzalez.es/portfolio/smartwateralcobendas",
    image1: "/assets/img/portfolio/alcobendas1.jpeg",
  },
  {
    id: 11,
    title: "Smart Horse · Horse and Facilities Monitoring",
    subtitle: "EOIT · 2015",
    category: "Smart Farming",
    image: "/assets/img/portfolio/smarthorsefront.png",
    description: "Monitor the health of your horses",
    detailImage: "/assets/img/portfolio/smarthorseback.jpeg",
    content: `
    <p>SmartHorse is a digital platform that thanks to new technologies and information systems allows you monitor the health of your horses (colics, birthmare,training,...) and the status of their stables (temperature, humidity, opening/closing doors, water....).</p>
    <p>All of it through a specific network of sensors and alarms generation in real time.</p>
    <p>Besides, the monitoring system is integrated within a modular management system customized to your needs and accesible from any device with internet access.</p>
    <p>Technology: SmartMote electronic devices are carefully designed for the Smart Horse Systema. These devices are designed for working autonomously and their main target is collecting data from different sensors for the Smart Horse Platform:
      <ul>
        <li>Horses Vital Signs</li>
        <li>Opening/Closing Doors</li>
        <li>Temperature and Humidity Box</li>
        <li>Water Consumption</li>
        <li>Access Control</li>
      </ul>
    </p>
    <p>Our Service: We offer a totally transparent service to the user so that our clients should only access the web platform to get the relevant information. Knowing all technical aspects of the system and collecting data from the sensors for showing on the web.</p>
    <p>Features:
      <ul>
        <li>Monitoring: The health of the horses (possible colic, marebirth, training, etc...). Positioning and tracking horses using GPS. Facilities Condition (boxes,water tanks, personal control,...)</li>
        <li>Alerts: System for generating alarms in real time. The system allows configure threshold for different parameters and tell different users if theses values are excedeed.</li>
        <li>Connectivity: Thanks to the sensor network and internet access it is possible to be connected to your system at any time from anywhere in the world. For communications between sensors short-range radio technology as well as mobile technology is used.</li>
        <li>Management: Integrated Management System with two main modules. Management of sensors data and associated alarms. Management of data, processes and activities related to the equine industry.</li>
      </ul>
    </p>`,
    event: "smarthorse_project_view",
    tags: "#iot #smarthorse #zigbee #cellular #cloud",
    brochure: "/assets/brochures/SmartHorseEnglish.pdf",
    company: "EOIT",
    date: "2015",
    path:"/portfolio/smarthorse",
    url:"https://asiergonzalez.es/portfolio/smarthorse",
    image1: "/assets/img/portfolio/smarthorse1.jpeg",
    image2: "/assets/img/portfolio/smarthorse2.jpeg",
    image3: "/assets/img/portfolio/smarthorse4.jpeg",
    image4: "/assets/img/portfolio/smarthorse3.jpeg",
    press1: "Revista Ecuestre",
    press2: "Engineering.com",
    press3: "Libelium World",
    presslink1:"/assets/brochures/ecuestre.pdf",
    presslink2:"https://www.engineering.com/story/wastewater-treatment-with-the-internet-of-things",
    presslink3:"https://www.libelium.com/libeliumworld/success-stories/smart-farming-monitoring-horses-equine-facility-management-waspmote/"
  },
  {
    id: 12,
    title: "Smart Irrigations",
    subtitle: "ARSON · 2009",
    category: "Smart Irrigations",
    image: "/assets/img/portfolio/irrigationsfront.png",
    description: "Innovative Irrigations Remote Control System",
    detailImage: "/assets/img/portfolio/irrigationsback.jpeg",
    content: `
    <p>AquArson devised an innovative irrigation remote control system of its own manufacture that responded to all the technological challenges of the modernization project. A public tender was submitted and the remote control implantation was awarded, which was deployed throughout the irrigation area.</p>
    <p>The keys to this system lie in its technological reliability and robustness. It is based on a very reliable radio communications network, with extremely low consumption and resistant to all the inconveniences of being outdoors, such as the large temperature fluctuations in the area.</p>
    <p>It consists of a fixed network of devices distributed in all the plots, which communicate through an OPC Driver with control software where all the data is received. The OPC Driver developed by AquArson allows the integration of all communication protocols; it is universal and open to electronic devices from all manufacturers. The Irrigation Community carries out the management through the irrigation control software and App, which allows access from a mobile or tablet</p>
    `,
    event: "regadios_project_view",
    tags: "#iot #irrigations #ismband #opcdriver",
    company: "ARSON",
    date: "2009",
    path:"/portfolio/irrigations",
    url:"https://asiergonzalez.es/portfolio/irrigations",
    image1: "/assets/img/portfolio/irrigations1.jpeg",
    image2: "/assets/img/portfolio/irrigations2.jpeg",
    image3: "/assets/img/portfolio/irrigations3.jpeg",
  },
  {
    id: 13,
    title: "IAQ for Safe Working Environments",
    subtitle: "SGS · 2023",
    category: "Smart Environment",
    image: "/assets/img/portfolio/iaqfront.png",
    description: "Indoor Air Quality for Safe Working Environments",
    detailImage: "/assets/img/portfolio/iaq.webp",
    content: `
    <p>In a world continuously adapting to emerging health threats, such as the COVID-19 pandemic, indoor air quality has become a pivotal concern in ensuring safe and healthy work environments. This project highlights our technical and proactive response to this global challenge.</p>
    <p>The primary goal was to develop an efficient system for monitoring and improving the air quality in indoor spaces, particularly in the context of COVID-19 prevention.</br></br> Our objectives included:</p>
    <ul>
        <li>Monitoring and Ensuring Indoor Air Quality: Implement advanced technology to continuously assess air quality in enclosed spaces.</li>
        <li>Improving Ventilation Conditions: Adjust ventilation systems based on collected data to optimize air circulation.</li>
        <li>Reducing COVID Exposure: Minimize the risk of airborne virus transmission by monitoring and controlling critical environmental parameters.</li>
    </ul>
    </br>
    <p>Project Features:</p>
    <ul>
        <li>Comprehensive Parameterization: Monitoring temperature, humidity, presence (PIR), carbon dioxide (CO2), and total volatile organic compounds (TVOC) for a thorough assessment.</li>
        <li>Advanced Communications: Utilizing LORA WAN for efficient and low-energy data transmission.</li>
        <li>IoT Platform based on Azure: Implementing a cloud-based solution for real-time data collection and analysis</li>
        <li>Installation of NFC Tags: Facilitating access and control over monitoring devices across various locations.</li>
        <li>Real-Time Monitoring: Ability to observe and respond to environmental changes instantly.</li>
    </ul></br>
    <p>Impact and Outcomes:</p>
    <p>The project has been a resounding success in creating safer and healthier work environments. The capability to monitor and react to air conditions in real time has significantly improved the quality of indoor spaces. This not only reduces potential exposure to COVID but also sets a new standard in workplace health and safety management.</p>
    <p>Conclusion</p>
    <p>This project represents a significant advancement in protecting health in enclosed environments. By combining cutting-edge technology with a proactive approach, we have established a new paradigm in indoor air quality, crucial in these challenging times and beyond.</p>

    `,
    event: "iaq_project_view",
    tags: "#iot #iaq #lora #azure",
    company: "SGS",
    date: "2023",
    path:"/portfolio/iaq",
    url:"https://asiergonzalez.es/portfolio/iaq"
  },
  {
    id: 18,
    title: "Exploring Discovery Rover",
    subtitle: "ASIER · 2024",
    category: "Remote Inspections",
    image: "/assets/img/portfolio/roverfront.png",
    description: "Next Generation of  Inspections  with Discovery Rver",
    detailImage: "/assets/img/portfolio/roverback.jpeg",
    content: `
    <p>In an era where technology leaps are simplifying complex tasks, the Discovery Rover is setting a new benchmark in remote inspections. Built with cutting-edge technology and advanced materials, this rover promises to revolutionize how we approach challenging environments and intricate inspection tasks.</p>
    <p><strong>Innovative Design</strong></p>
    <p>The Discovery Rover is a marvel of modern engineering, primarily constructed using 3D printing technology. Utilizing robust materials such as PETG and carbon fiber, it boasts a lightweight yet durable frame, perfect for navigating through harsh terrains. This innovative design approach not only enhances the rover's operational efficiency but also its sustainability.</p>
    <p><strong>Advanced Technologies for Enhanced Mobility</strong></p>
    <p>To tackle a variety of obstacles, the Discovery Rover is equipped with an axle balancing system and axial thrust ball bearings at pivot points, ensuring smooth movement across uneven surfaces. Its traction rods and shock absorbers maintain balance, while a six-wheel drive system powered by 130 RPM brush motors allows for superior maneuverability. Whether climbing over rocks or navigating through debris, Discovery ensures reliable and stable movement in unpredictable environments.</p>
    <p><strong>Robotic and Communication Capabilities</strong></p>
    <p>One of the most striking features of the Discovery Rover is its five-axis robotic arm, which is capable of precise and complex manipulations. This makes it invaluable for tasks requiring detailed inspection and handling of sensitive materials. Control is versatile and user-friendly, facilitated by a PSP4 controller and a mobile app developed using Flutter, making it accessible from anywhere.</p>
    <p><strong>Technological Integration</strong><p>
    <p>The rover is not just mechanically advanced but is also a hub of digital innovation. It employs computer vision technologies based on YOLOv5 for obstacle and object detection, ensuring safety and efficiency. Voice assistance is powered by ChatGPT and Google Speech-to-Talk, enhancing user interaction and command execution. Furthermore, Discovery is integrated with platforms like OpenRemote and MQTT for seamless data management and communication, using websocket for data transmission and http streaming for live feeds.</p>
    <p><strong>Connectivity and Power</strong></p>
    <p>Keeping the rover connected in various settings is crucial; thus, it includes options for cell, WiFi, and Bluetooth connectivity, with an optional GPS feature for location tracking. Each unit is powered by independent power sources, offering up to an hour of autonomy, allowing extensive and uninterrupted inspection missions</p>
    <p><strong>Conclusion</strong></p>
    <p>The Discovery Rover is not just a tool but a significant advancement in remote inspection technology. Its integration of mechanical innovation and digital technology promises a future where no environment is too challenging to access or inspect. For industries ranging from construction to environmental monitoring, Discovery offers a new horizon of possibilities.</p>
  
    `,
    event: "rover_project_view",
    tags: "#iot #rover #computervision #chatGPT #gTTS",
    brochure: "/assets/brochures/discovery.pdf",
    company: "ASIER",
    date: "2024",
    path:"/portfolio/rover",
    url:"https://asiergonzalez.es/portfolio/rover",
    video1: "7FQkfd3lthg",
    video:"Exploring Discovery",
    videolink1: "https://www.youtube.com/watch?v=7FQkfd3lthg"
  },
  {
    id: 17,
    title: "Cold Chain Monitoring",
    subtitle: "SGS · 2024",
    category: "Health",
    image: "/assets/img/portfolio/coldchainfront.png",
    description: "Ensuring Temperature Stability",
    detailImage: "/assets/img/portfolio/coldchainback.jpg",
    content: `
    <p><strong>Introduction</strong></p>
    <p>The integrity of medicines, vaccines, and other pharmaceutical products is critically dependent on maintaining precise temperature conditions from production to delivery. This meticulous temperature control process, known as the cold chain, ensures that these products remain safe and effective. Advanced cold chain monitoring systems are at the forefront of this effort, providing robust solutions to track and manage temperatures throughout the supply chain.</p>
    <p><strong>Understanding Cold Chain Monitoring</strong></p>
    <p>Cold chain monitoring refers to the continuous tracking of temperature-sensitive products during storage and transportation. This involves using a combination of sensors, data loggers, communication networks, and software to ensure that products remain within the specified temperature range at all times.</p>
    <p><strong>Components of Cold Chain Monitoring Systems</strong></p>
    <p>
       <ul>
        <li>Sensors and Actuators: Sensors measure various parameters such as the angle of the sun, ambient temperature, and panel position. Actuators are mechanical devices that adjust the position of the panels based on the data from sensors.</li>
        <li>Control Units: These are the brains of the system, processing data from sensors and issuing commands to actuators. Modern control units are equipped with algorithms that predict the sun’s path and adjust the panels accordingly.</li>
        <li>Communication Networks: These networks enable the transmission of temperature data to central monitoring systems. This can be achieved through wireless technologies, such as Bluetooth, Wi-Fi, or cellular networks, allowing for real-time monitoring and alerts.</li>
        <li>Monitoring Software: This software provides a comprehensive user interface for stakeholders to oversee the cold chain. It displays real-time temperature data, sends alerts if temperatures deviate from acceptable ranges, and generates reports for regulatory compliance and quality assurance.</li>
        </ul>
    </p>
    <p><strong>Benefits of Cold Chain Monitoring</strong></p>
    <p>
    <ul>
     <li>Ensured Product Integrity: Continuous monitoring ensures that medicines and vaccines remain within their required temperature ranges, preserving their efficacy and safety.</li>
     <li>Regulatory Compliance: Many regions have stringent regulations regarding the storage and transportation of pharmaceutical products. Cold chain monitoring systems help companies comply with these regulations by providing detailed records of temperature conditions.</li>
     <li>Reduced Waste and Loss: By detecting and responding to temperature excursions in real-time, these systems can prevent spoilage and reduce the loss of valuable products, thereby saving costs./li>
     <li>Enhanced Traceability and Accountability: Detailed monitoring provides a transparent record of the entire supply chain, enhancing traceability and accountability. This is particularly important in the event of product recalls or quality audits.</li>
     </ul>
    </p>
      <p>Implementing an effective cold chain monitoring system can be challenging. It requires significant investment in technology and infrastructure. Ensuring reliable communication in various environments, from storage facilities to moving vehicles, can also be difficult. Moreover, the integration of these systems with existing logistics and inventory management systems needs careful planning and execution.</p>
    <p>The future of cold chain monitoring is set to be revolutionized by advancements in technology. Internet of Things (IoT) devices are becoming more sophisticated, offering more accurate and reliable temperature sensing and data transmission. Artificial intelligence and machine learning are poised to enhance predictive analytics, allowing for proactive management of temperature excursions before they occur. Blockchain technology also holds promise for further enhancing traceability and security in the cold chain.</p>
    <p>In conclusion, cold chain monitoring is essential for ensuring the safety and effectiveness of temperature-sensitive pharmaceutical products. By leveraging advanced technologies, these systems provide real-time tracking, enhance regulatory compliance, and reduce waste. As technology continues to evolve, the cold chain will become even more robust, further safeguarding public health and the integrity of critical medical supplies.</p>
      `,
    event: "coldchain_project_view",
    tags: "#iot #coldchain",
    company: "SGS",
    date: "2024",
    path:"/portfolio/coldchain",
    url:"https://asiergonzalez.es/portfolio/coldchain",
  },
  {
    id: 16,
    title: "OCM for Enhanced Machinery Health",
    subtitle: "SGS · 2024",
    category: "Smart Industry",
    image: "/assets/img/portfolio/ocmfront.png",
    description: "Online Oil Condition Monitoring",
    detailImage: "/assets/img/portfolio/ocmback.jpg",
    content: `
    <p>In a digitally-driven industry landscape, monitoring the condition of machinery oils has become a critical component for enhancing operational efficiency and preventing costly equipment failures. This initiative outlines our technical and forward-thinking response to this essential industrial need.</p>
    <p>The primary goal was to create an accessible online system for comprehensive oil condition monitoring, helping businesses prevent machinery downtime and optimize maintenance schedules.</p>
    <p>Our objectives included:</p>
    <ul>
        <li>Remote Monitoring of Oil Condition: Utilize advanced IoT sensors to measure and analyze key oil parameters such as viscosity, contamination, and temperature from any location.</li>
        <li>Predictive Maintenance: Leverage data analytics to predict potential equipment failures and schedule timely maintenance interventions.</li>
        <li>Extending Equipment Life: Reduce wear and tear by ensuring optimal lubrication conditions, significantly extending machinery lifespan.</li>
        </ul>
    </br>
    <p><strong>Project Features:</strong></p>
    <p>
    <ul>
      <li>Dynamic Parameter Monitoring: Track changes in oil quality through measurements of temperature, viscosity, metallic particles, and acidity.</li>
      <li>Cloud-Based Data Analysis: Utilize a cloud platform for integrating, processing, and visualizing data, ensuring real-time insights into equipment health.</li>
      <li>IoT Platform Leveraging Azure: Implementing a robust solution that offers scalable data management and security for enterprise applications.</li>
      <li>Machine Learning Algorithms: Employ advanced algorithms to predict potential failures and recommend maintenance actions based on historical and real-time data.</li>
      <li>User-Friendly Online Interface: Provide a comprehensive dashboard for users to monitor their equipment’s oil condition remotely and securely.
      </li>
      </ul>
      </p>
      <p><strong>Impact and Outcomes:</strong></p>
      <p>This project has successfully transformed how businesses monitor and manage the health of their machinery. The ability to predict and respond to oil condition changes online has not only minimized downtime but also optimized maintenance costs. This innovative approach enhances machine performance and reliability, setting a new age in industrial maintenance.</p>
      <p><strong>Conclusion:</strong></p>
      <p>This project signifies a major leap forward in industrial maintenance practices. By integrating cutting-edge IoT technology and machine learning, we provide a comprehensive online service that ensures ongoing machinery health and operational continuity, crucial for maintaining high standards of productivity and efficiency in today's competitive markets.</p>
    `,
    event: "openremote_project_view",
    tags: "#iot #ocm #machinelearning #cloud",
    company: "SGS",
    website: "https://www.sgs.com/en/services/oil-condition-monitoring-ocm",
    date: "2024",
    path:"/portfolio/ocm",
    url:"https://asiergonzalez.es/portfolio/ocm",
    image1: "/assets/img/portfolio/ocm1.png",
  },
  {
    id: 14,
    title: "Open Remote based IoT Platform",
    subtitle: "ASIER · 2024",
    category: "IoT Platform",
    image: "/assets/img/portfolio/openremotefront.png",
    description: "IoT Open Source Platform",
    detailImage: "/assets/img/portfolio/openremoteback.png",
    content: `
    <p><strong>What OpenRemote is</strong></p>
    <p>OpenRemote is a platform that specializes in open-source Internet of Things (IoT) solutions. It's designed to be a comprehensive management environment that can integrate various types of IoT devices and data sources, enabling the creation of smart solutions in various domains such as smart cities, smart buildings, and energy management.</p>
    <p>Key features and aspects of OpenRemote include::</p>
    <ul>
        <li>Open-Source Nature: Being open-source, it allows developers and organizations to customize and extend the platform according to their specific needs.</li>
        <li>Device and Protocol Agnostic: It can connect with a wide range of devices and supports various communication protocols. This flexibility is crucial for integrating diverse IoT ecosystems.</li>
        <li>Data Management and Analytics: OpenRemote provides tools for collecting, storing, and analyzing data from connected devices. This can be used for monitoring, reporting, and making data-driven decisions.</li>
        <li>Automation and Control: Users can set up rules and workflows to automate responses based on data inputs, which is essential for managing complex IoT systems.</li>    
        <li>User Interface: It offers tools to create custom user interfaces and dashboards, allowing for a tailored view of the IoT ecosystem and its performance.</li>
        <li>Security: As with any IoT system, security is a critical aspect, and OpenRemote includes features to ensure secure communication and data handling.</li>
        </ul>
    </br>
    <p>OpenRemote is particularly suited for users and organizations looking for a customizable and scalable IoT solution, including city planners, energy managers, and facility managers. The platform's open-source nature also makes it a popular choice for innovation and research in IoT applications</p>
    <p><strong>My OpenRemote Deployment:</strong></p>
    <p>
    <ul>
      <li>100% White Labeling - Crafting Your Brand Identity: One of OpenRemote's standout features is its 100% white-labeling capability. This means you can fully brand your IoT platform with your company's logo, color scheme, and domain name, offering a seamless brand experience to your users. This level of customization enhances your brand's presence and identity in every interaction with the platform.</li>
      <li>Running on a Docker Container - Ease and Flexibility: Docker container deployment simplifies installation and management. Docker containers ensure a consistent environment across different systems, making deployment, scaling, and testing more straightforward. This approach also facilitates updates and reduces compatibility issues, making OpenRemote an ideal choice for diverse IT ecosystems.</li>
      <li>Running on Ubuntu Server - Reliability and Performance: Running OpenRemote on Ubuntu Server brings the benefits of stability, security, and performance. Ubuntu's widespread use and strong community support make it a reliable choice for hosting OpenRemote, ensuring your IoT platform runs smoothly and securely.
      <li>Asset Management: The manager is the interface to create, connect and manage assets. Asset types can be fully customised, visualised on maps and asset pages, as well as stored or used for rules and dashboards.</li>
      <li>Location Services: Geographical overview of your assets and their current values. Modify the map to fit your area of interest and make use of geofencing to trigger alarms or send notifications.</li>
      <li>Data Visualization: On the insights page any data source or device can be compared in time and with each other. The created page can be used as a comprehensive report on the status of your project.</li>
      <li>Digital Twins: Define a perfect digital twin of your devices or physical environment, using asset types. Configure your own asset types, attributes and behaviour to make your application fit your reality.</li>
      <li>Auto Provisioning: Secure auto provisioning allows devices to create and connect to a new asset. Define the asset type and roles, to enable secure two-way communication, over MQTT, with the respective attributes.</li>
      <li>Multi Tenancy Access: Large number of users just get access to their own assets and environments. Use Realms (multi tenancy), Roles, and Restricted users for role based, or device access control (RBAC or DAC).</li>
      <li>Protocol Agents: Protocol Agents connect external devices and services. Generic protocols as HTPP, SNMP, MQTT, Bluetooth, Serial, TCP, UDP, Z-wave are included, as well as vendor specific ones like KNX and Velbus. Create your own via a custom project</li>
      <li>Manager APIs: Manager APIs allow external devices or services to connect to OpenRemote as clients. OpenRemote includes an MQTT (Broker), HTTP, and Websocket API. Service users for headless access use standard OAuth 2.0 techniques.</li>
      <li>Gateways: Deploy multiple OpenRemote instances for each of your customers, and another instance centrally. All your customer instances can be connected to the central instance as a gateway, synchronising the connected assets and attributes.</li>
      <li>When-Then Rules: With When-Then rules you can monitor any attribute or time condition and trigger an action on an attribute, or sending push notifications or emails. Using web hooks allows for integration in your existing workflows.</li>
      <li>Flow Rules: Flow rules can process and manipulate data to create new data and attributes. Use flow rules for data conversions, combine multiple attributes into one new attribute or even dynamically create sentences.</li>
      <li>Groovy Rules: Groovy is a scripting language which allows for defining more complex automation logic. It gives you the freedom to define almost anything in a running system, e.g. defining group behaviour or your own type of alert condition.</li>    
      </ul>
      </p>
      <p>OpenRemote's customization capabilities make it a powerhouse for creating IoT solutions that are as unique as your requirements. Whether it's for a smart city project, industrial automation, or energy management, OpenRemote’s flexibility in white labeling, deployment options, and connectivity ensures that you can build an IoT platform that aligns perfectly with your operational needs and brand identity.</p>
    `,
    event: "openremote_project_view",
    tags: "#iot #openremote #platform",
    company: "ASIER",
    website: "http://eoit.es",
    date: "2024",
    path:"/portfolio/openremote",
    url:"https://asiergonzalez.es/portfolio/openremote",
    image1: "/assets/img/portfolio/openremote1.png",
    image2: "/assets/img/portfolio/openremote2.png",
    image3: "/assets/img/portfolio/openremote3.png",
  },
  {
    id: 15,
    title: " My Journey with TTN Madrid",
    subtitle: "ASIER · 2024",
    category: "LoRaWAN Network",
    image: "/assets/img/portfolio/ttnfront.png",
    description: "Enhancing Madrid LoRaWAN Community Connectivity",
    detailImage: "/assets/img/portfolio/ttnback.png",
    content: `
    <p><strong>Introduction</strong></p>
    <p>I embarked on a transformative project in the realm of community-driven connectivity by installing and configuring a gateway for The Things Network (TTN) in Madrid. This initiative was not just a personal achievement, but a significant contribution to the city, enabling public LoRa coverage and supporting the broader community.</p>
    <p><strong>The Things Network Madrid - A Community Milestone</strong></p>
    <p>Founded on June 16, 2016, The Things Network Madrid emerged with a vision to establish a network facilitating data collection from remote and mobile sensors, and to interact with various systems. This network has become a cornerstone for innovation and community engagement in the city.</p>
    <p><strong>The Power of LoRa Technology:</strong></p>
    <p>LoRa technology stands at the heart of this project. Each gateway added to the network extends remarkable coverage - ranging from hundreds of meters in densely populated urban areas to several kilometers in rural settings. This expansive reach is a testament to the efficiency and adaptability of LoRa technology.</p> 
    <p><strong>Applications and Impact:</strong></p>
    <p>The network opens avenues for diverse applications, such as creating citizen information systems on noise or air pollution. Moreover, it offers a platform for professional services, including community access control, pet tracking, and monitoring of gardens, machinery, and livestock. The network's versatility showcases its potential in both public service and commercial ventures.</p>
    <p><strong>The Power of LoRa Technology:</strong></p>
    <p>LoRa technology stands at the heart of this project. Each gateway added to the network extends remarkable coverage - ranging from hundreds of meters in densely populated urban areas to several kilometers in rural settings. This expansive reach is a testament to the efficiency and adaptability of LoRa technology.</p>
    <p><strong>A Collective Resource:</strong></p>
    <p>Participation in a community network offers numerous benefits. It provides connectivity in areas lacking commercial services and fosters a sense of independence and organizational capability. Other motivations include:</p>
    <p>
    <ul>
      <li>Desire for an alternative to private and institutional networks.</li>
      <li>Aspiration for greater autonomy and organizational opportunities.</li>
      <li>Access to open and affordable networks, both financially and in terms of required expertise.</li>
      <li>Enhanced privacy and data security.</li>
      <li>Curiosity to experiment with new technologies.</li>
      <li>Belief in knowledge sharing as a tool for empowering citizens.</li>
      <li>Contribution to job creation and social cohesion.</li>
      <li>Personal satisfaction from being part of a pioneering initiative.</li>
      </ul>
      </p>
      <p>My involvement with The Things Network Madrid deploying two different LoRaWAN Gateways is more than a technical endeavor; it's a commitment to community empowerment, innovation, and collaborative progress. By leveraging LoRa technology and fostering a communal approach, we are paving the way for a more connected, empowered, and technologically advanced society.</p>
    `,
    event: "ttn_project_view",
    tags: "#iot #ttn #lorawan",
    company: "ASIER",
    website: "https://www.thethingsnetwork.org/community/madrid/",
    date: "2024",
    path:"/portfolio/ttn",
    url:"https://asiergonzalez.es/portfolio/ttn",
    image1: "/assets/img/portfolio/ttn1.png"
  },
  {
    id: 19,
    title: "Thermo Solar Plants's Panels Controlling",
    subtitle: "ELECNOR · 2008",
    category: "Telecontrol",
    image: "/assets/img/portfolio/thermosolarfront.png",
    description: "Controlling Panels Direction",
    detailImage: "/assets/img/portfolio/thermosolarback.jpg",
    content: `
    <p><strong>Introduction</strong></p>
    <p>Thermo solar plants, a crucial component of renewable energy infrastructure, harness the sun’s power to generate electricity. These plants rely on an array of solar panels to capture solar energy efficiently. To maximize energy capture, the panels must be precisely aligned with the sun’s position throughout the day. This alignment is achieved through a sophisticated system known as telecontrol.</p>
    <p><strong>Understanding Telecontrol</strong></p>
    <p>Telecontrol refers to the remote monitoring and control of systems and machinery. In the context of thermo solar plants, telecontrol involves using advanced technology to monitor the position of solar panels and adjust their orientation to ensure they are always optimally aligned with the sun.</p>
    <p><strong>Components of Telecontrol Systems:</strong></p>
    <p>
       <ul>
        <li>Sensors and Actuators: Sensors measure various parameters such as the angle of the sun, ambient temperature, and panel position. Actuators are mechanical devices that adjust the position of the panels based on the data from sensors.</li>
        <li>Control Units: These are the brains of the system, processing data from sensors and issuing commands to actuators. Modern control units are equipped with algorithms that predict the sun’s path and adjust the panels accordingly.</li>
        <li>Communication Networks: These networks enable real-time data transmission between sensors, control units, and operators. Wireless communication is often employed to connect various components over large areas without extensive cabling.</li>
        <li>Monitoring Software: This software provides a user interface for operators to oversee the system. It displays real-time data, alerts for maintenance issues, and historical performance metrics.</li>
        </ul>
    </p>
    <p><strong>Benefits of Telecontrol in Thermo Solar Plants</strong></p>
    <p>
    <ul>
     <li>Increased Efficiency: By continuously adjusting the panels to the optimal angle, telecontrol systems ensure maximum energy capture. This can significantly boost the overall efficiency of the plant.</li>
     <li>Reduced Operational Costs: Automated systems reduce the need for manual adjustments and regular on-site inspections, lowering labor costs and minimizing the potential for human error.</li>
     <li>Enhanced Reliability: Real-time monitoring allows for the early detection of issues such as panel misalignment or mechanical failures. Prompt corrective actions can prevent minor issues from escalating into major problems./li>
     <li>Data-Driven Decisions: The vast amounts of data collected by telecontrol systems can be analyzed to improve plant operations, forecast maintenance needs, and optimize performance over time.</li>
     </ul>
    </p>
      <p>While telecontrol systems offer numerous advantages, their implementation can be complex and costly. Initial setup requires significant investment in hardware and software. Additionally, ensuring reliable communication over large areas can be challenging, especially in remote locations. However, advancements in technology and decreasing costs of components are making telecontrol increasingly accessible and cost-effective.</p>
    `,
    event: "thermosolar_project_view",
    tags: "#iot #telecontrol #solar",
    company: "ELECNOR",
    date: "2008",
    path:"/portfolio/thermosolar",
    url:"https://asiergonzalez.es/portfolio/thermosolar",
  }
];

export default projects;
