import React from "react";
const Home = () => {
  return (
    <>

<header className="vh-100">
  <div className="text-center bg-image h-100">
        <video
          style={{ minWidth: "100%", minHeight: "100%" }}
          playsInline
          autoPlay
          muted
          loop
        >
          <source
            className="h-100"
            src="https://mdbootstrap.com/img/video/Lines.mp4"
            type="video/mp4"
          />
        </video>
        <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
          <div className="d-flex justify-content-center align-items-center h-100">
            <div className="mainText">
            <span className="mainh1">Driven by <span style={{fontWeight: "bold"}}>innovation</span>, digital solutions & the <span style={{fontWeight: "bold"}}>people</span> that use them.</span>
            <br/>
            <span className="mainh1">.</span>
            <br/>
            <span className="mainh2">Imagine stepping into a world where technology meets imagination, and innovation is more than a buzzword—it's a way of life. I'm Asier González, and I'm all about turning cool ideas into real-life tech solutions. I've been at this tech game for 15 years, from the startup trenches to multinational boardrooms, I've immersed myself in the dynamic landscape of innovation. Picture this: creating products from thin air, learning how to lead a team, and making decisions that count.</span>
 </div>
          </div>
        </div>
      </div>
</header>



  
    </>
  );
};

export default Home;
