const videos = [
  {
    id: 1,
    title: "IoT Teaser",
    subtitle: "Asier · TPF INGENIERIA · 2017",
    image: "/assets/img/videos/mmd.png",
    event: "iot_teaser_video_view",
    tags: "#mmd17 #iot",
    date: "2017",
    path:"/portfolio/mmdvideo",
    url:"https://asiergonzalez.es/portfolio/mmdvideo",
    videopath:"https://www.youtube.com/embed/uztREPbioJ4"
  },
  {
    id: 2,
    title: "Smart Horse App",
    subtitle: "Asier · Eoit · 2015",
    image: "/assets/img/videos/smarthorseapp.png",
    event: "smarthorse_app_video_view",
    tags: "#smarthorse #iot",
    date: "2015",
    path:"/portfolio/smarthorseappvideo",
    url:"https://asiergonzalez.es/portfolio/smarthorseappvideo",
    videopath:"https://www.youtube.com/embed/ZT9EEDXE_kk"
  },
  {
    id: 3,
    title: "Smart Horse Promo",
    subtitle: "Asier · Eoit · 2015",
    image: "/assets/img/videos/smarthorsepromo.png",
    event: "sc_introduction_services_view",
    tags: "#smartcities #iot",
    date: "2015",
    path:"/portfolio/smarthorsepromovideo",
    url:"https://asiergonzalez.es/portfolio/smarthorsepromovideo",
    videopath:"https://www.youtube.com/embed/Q3DBqwCkseU"
  },
  {
    id: 4,
    title: "Smart Horse Logo",
    subtitle: "Asier · Eoit · 2015",
    image: "/assets/img/videos/smarthorselogo.png",
    event: "smarthorse_logo_video_view",
    tags: "#smarthorse #iot",
    date: "2015",
    path:"/portfolio/smarthorselogovideo",
    url:"https://asiergonzalez.es/portfolio/smarthorselogovideo",
    videopath:"https://www.youtube.com/embed/lGnW_xpU6pk"
  },
  {
    id: 5,
    title: "Eoit",
    subtitle: "Asier · Eoit · 2015",
    image: "/assets/img/videos/eoit.png",
    event: "eoit_video_view",
    tags: "#eoit #iot ",
    date: "2015",
    path:"/portfolio/eoitvideo",
    url:"https://asiergonzalez.es/portfolio/eoitvideo",
    videopath:"https://www.youtube.com/embed/_GVJZHkn3bk"
  },
  {
    id: 6,
    title: "Exploring Discovery",
    subtitle: "Asier · 2024",
    image: "/assets/img/videos/discovery.png",
    event: "discovery_video_view",
    tags: "#discovery #remoteinspections #iot ",
    date: "2024",
    path:"/portfolio/exploringdiscoveryvideo",
    url:"https://asiergonzalez.es/portfolio/exploringdiscoveryvideo",
    videopath:"https://www.youtube.com/embed/7FQkfd3lthg"
  }
];

export default videos;
